@import '../../../assets/styles/variables.scss';

.square-container {
  position: relative;
  display: flex;
  margin-top: 125px;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  // .zoom-out {
  //   animation: zoomOutDown 2s ease-in-out;
  //   animation-fill-mode: forwards;
  // }
  .square {
    height: 10.5vw;
    width: 10.5vw;
    opacity: 1;
    z-index: 1;
    transition: all 0.65s ease-in;


    &:hover {
      cursor: pointer;
    }

    &.left {
      background-color: $red;
      &.clicked {
        background-color: $black;
        cursor: none;
        outline: none;
      }
    }

    &.middle {
      background-color: $yellow;
      &.clicked{
        background-color: $black;
        cursor: none;
        outline: none;
      }
    }

    &.right {
      background-color: $blue;
      &.clicked{
        background-color: $black;
        cursor: none;
      }
    }
  }

}
