@import '../../assets/styles/variables.scss';

.home-page {
  height: 100vh;
  width: 100vw;
  position: relative;

//-------------------------------ROUND MENU + LINKS----------------------------------
  .round-menu {
    width: 260px;
    height: 260px;
    border-radius: 50%;
    background-color: white;
    position: fixed;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: appear 2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    z-index: -2;
  }

  .links {
    position: fixed;
    height: 136px;
    width: 100vw;
    top: 276px;
    transform: translate(-50%, -50%);
    left: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 33px;
    font-weight: 700;
    z-index: 100;
    margin: 0 auto;

    a {
      position: relative;
      color: $black;
      font-family: 'Futura', sans-serif;
      opacity: 2;
      animation: appear 2s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      opacity: 0;
      text-decoration: none;

      &:hover {
        cursor: none;
        transition: 0.6s;
        text-decoration: underline;
        &:nth-child(1) {
          color: $blue;
        }
        &:nth-child(2) {
          color: $yellow;
        }
        &:nth-child(3) {
          color: $red;
        }
      }
    }
  }
  //---------------------------------TRIANGLE MENU-----------------------------------
  .triangle-menu-container {
    width: 16px;
    height: 162px;
    border-left: 565px solid transparent;
    border-right: 565px solid transparent;
    border-bottom: 674px solid #FF2813;
    position: fixed;
    top: 270px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -4;
    opacity: 0;
    animation: appear 2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;

  }
//---------------------------------HALF SCREAN RED-----------------------------------
  .half-screen-red {
    background-color: $red;
    height: 50vh;
    width: 100vw;
    position: fixed;
    z-index: -2;
    bottom: 0%;
    animation : appear 3s ease-in-out;
    animation-fill-mode: forwards;
  }

//---------------------------------LIGHBULBS-----------------------------------------
  .lightbulb {
    animation : appear 2s ease-in;
    animation-fill-mode: forwards;
    &.yellow-mid {
      color: $yellow;
    }
  }

}

// ---------------------------------ANIMATIONS---------------------------------------
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menu-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
    z-index: 1;
  }
}
