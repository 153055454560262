@import '../../assets/styles/variables.scss';


.contact-page {
  height: 100vh;
  width: 100vw;


//--------------------------HALFSCREEN red--------------------------
  .half-screen-red {
    background-color: $red;
    height: 50vh;
    width: 100vw;
    position: fixed;
    z-index: -2;
    bottom: 0%;
  }
// ------------------------------SOCIALS------------------------------------
  .socials {
    height: 49vh;
    width: 57vw;
    opacity: 0;
    position: fixed;
    bottom: -1px;
    right: 2vw;
    border: 1px solid black;
    animation: appear 2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;


    .london-map {
      width: 40%;
      top: 13px;
      position: absolute;
      right: 17px;
      border: 0.4vw solid $black;
      opacity: 0.8;
    }

    ul {
      list-style: none;
      display: block;
      word-spacing: 20px;
      padding: 0;
    }

    a {
      text-decoration: none;
      color: $black;
      font-size: 5.7vw;
      font-weight: 600;
      margin: 4%;
      &:hover {
        color: whitesmoke;
        cursor: pointer;
        text-decoration: overline;
      }
    }
  }
//------------------------------PROFILE PIC------------------------------------
  .profile-img {
    bottom: 37vh;
    filter: grayscale(100%);
    opacity: 0;
    position: fixed;
    right: 62vw;
    width: 60vh;
    z-index: -1;
    animation: appear 3s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }
// ------------------------------TRIANGLE MENU---------------------------------
  .triangle-menu-container {
    width: 20px;
    height: 147px;
    border-left: 795px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 692px solid rgba(255, 40, 19, 0.70);
    position: fixed;
    top: -135px;
    right: 5.8vw;
    z-index: -4;
    opacity: 0;
    animation: appear 2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }
 // ------------------------------ROUND MENU---------------------------------
  .round-menu {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.785);
    position: fixed;
    top: 30%;
    right: 8%;
    opacity: 0;
    animation: appear 2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    z-index: -2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 600;
    color: $red;

    &:after {
      content: 'Contact me';
      margin-top: -15px;
      text-decoration: overline;
    }
  }
// ------------------------------LIGHTBULB---------------------------------

    .lightbulb {

      &.right-bulb {
        animation: rotate 2s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
      }

      &.yellow-right {
        color: $yellow;
      }
    }

  //--------------------------LINE BACK--------------------------
  .line-back {
    z-index: -4;
    animation-delay: 6s;
  }
//-----------------------------CONTACT FORM---------------------------
  .contact-form {
    height: 49vh;
    width: 37vw;
    margin-top: 20px;
    left: 2vw;
    position: fixed;
    bottom: 1px;

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        overflow: hidden;
        display: block;
        position: relative;
        opacity: 0;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
        clear: both;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input[type="text"],
    input[type="email"] {
      width: 100%;
      border: 0;
      background: #EEDFCC;
      height: 50px;
      font-size: 16px;
      color: $black;
      padding: 0 20px;
      box-sizing: border-box;
      font-family: 'Futura';
    }

    textarea {
      width: 100%;
      border: 0;
      background: #EEDFCC;
      height: 50px;
      font-size: 16px;
      color: $black;
      padding: 20px;
      min-height: 150px;
      box-sizing: border-box;
      font-family: 'Futura';
    }

    .flat-button {
      color: #E8B503;
    background: #343938;
    font-size: 19px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 2px solid #E8B503;
    float: right;
    border-radius: 30px;

      &:hover {
        background: $yellow;
        color: $black;
        cursor: pointer;
        text-decoration: overline;
        counter-reset: none;
      }

      // &:active {
      //   animation: fadeOutUp 1s ease-in-out;
      //   animation-fill-mode: forwards;

      // }
    }
  }
  //-----------------------------ANIMATIONS--------------------------

  @keyframes rotate {
    0% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(205deg);
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}
