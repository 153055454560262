
@import 'animate.css/animate.css';
@import '~loaders.css/src/animations/pacman.scss';


@font-face {
  font-family: 'Futura';
  src: url(./assets/fonts/futura.woff2);
}

$breakpoints: (
  phone-only: 599px,
  tablet-portrait-up: 600px,
  tablet-landscape-up: 900px,
  desktop-up: 1200px,
  big-desktop-up: 1800px
);

@mixin for-size($size) {
  $width: map-get($breakpoints, $size);
  @media (min-width: $width) {
    @content;
  }
}

@mixin phone-only {
  @include for-size(phone-only) { @content; }
}

@mixin tablet-portrait-up {
  @include for-size(tablet-portrait-up) { @content; }
}

@mixin desktop-up {
  @include for-size(desktop-up) { @content; }
}

input,
textarea {
  font-family: 'Futura';
}

body {
  font-family: 'Futura';
  background-color: #EBDCC9;
}


.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;
}
