@import '../../assets/styles/variables.scss';


.projects-page {
  height: 100vh;
  width: 100vw;
// ---------------------------------PROJECTS LINKS---------------------------------
  .link-container {
    position: fixed;
    max-height: 50vh;
    height: 50vh;
    width: 100%;
    bottom: 0%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    z-index: 3;
    justify-content: space-around;
    text-orientation: upright;


    a {
      margin-top: 5%;
      position: sticky;
      color: $black;
      font-family: 'Futura', sans-serif;
      opacity: 2;
      animation: appear 2s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      opacity: 0;
      text-orientation: upright;
      word-break: break-word;

      &:hover {
        cursor: none;
        transition: 0.6s;
        color: #E4D5BB;
        text-decoration: overline
      }
    }

    .melo-link {
      font-size: 11vw;
      width: 33vw;
    }

    .deadgood-link {
      font-size: 6vw;
      width: 33vw;
    }

    .barb-link {
      width: 33vw;
      font-size: 6vw;
    }

    .link-separator {
      position: relative;
      height: 100%;
      border-left: 4px solid $black;
      transform-origin: bottom;
      bottom: 0%;
      opacity: 0;
      animation: appear 2s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 2s;
    }
  }

//---------------------------------PICASSO IMG---------------------------------
  .picasso-img {
    right: -12vw;
    position: fixed;
    width: 48%;
    opacity: 0;
    animation: slide-up 5s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    z-index: -3;
  }
// ---------------------------------ROUND MENU----------------------------------
  .round-menu {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.785);
    position: fixed;
    top: 30%;
    left: 8%;
    opacity: 0;
    animation: appear 2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;
    z-index: -2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 600;
    color: $blue;

    &:after {
      content: 'Projects';
      margin-top: -15px;
      text-decoration: overline;
    }
  }
  // ---------------------------------LINE BACK----------------------------------
  .line-back {
    z-index: -4;
    animation-delay: 6s;
  }

  // ---------------------------------TRIANGLE MENU----------------------------------
  .triangle-menu-container {
    width: 20px;
    height: 147px;
    border-left: 4px solid transparent;
    border-right: 795px solid transparent;
    border-bottom: 692px solid rgba(3, 98, 182, 0.8352941176);
    position: fixed;
    top: -135px;
    left: 5.8vw;
    z-index: -4;
    opacity: 0;
    animation: appear 2s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }

  //--------------------------HALFSCREEN BLUE--------------------------
  .half-screen-blue {
    background-color: $blue;
    height: 50vh;
    width: 100vw;
    position: fixed;
    z-index: -2;
    bottom: 0%;
  }

//-----------------------------LIGHTBULBS--------------------------
  .lightbulb {

    &.left-bulb {
      animation: rotate-right 2s ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
    }
    &.yellow-left {
      color: $yellow;
    }
  }
}

//-----------------------------ANIMATIONS--------------------------

@keyframes slide-up {
  0% {
    bottom: 0vh;
  }
  100% {
    bottom: 50vh;
    opacity: 1;
  }
}

@keyframes rotate-right {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(155deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
