@import '../../assets/styles/variables.scss';

.landing-page {
  height: 100vh;
  width: 100vw;

  img {
    position: relative;
    height: 14vw;
    object-fit: cover;
    transform: translateX(-50%);
    left: 50%;
    top: 5rem;
  }

  .line {
    background-color: $black;
    position: fixed;
    width: 1.8vw;
    z-index: 1;
    transform-origin: bottom center;

    &.bottom-left {
      bottom: 0;
      height: 55%;
      left: 7vw;
      transform: scaleY(0);
      animation: extendUp 1.5s ease forwards;
      animation-delay: 0.5s;
    }

    &.bottom-right {
      bottom: 0;
      height: 45%;
      left: 12vw;
      transform: scaleY(0);
      animation: extendUp 1.5s ease forwards;
      animation-delay: 0.7s;
    }

    &.top-left {
      top: 0;
      height: 45%;
      right: 12vw;
      transform-origin: top center;
      transform: scaleY(0);
      animation: extendDown 1.5s ease forwards;
      animation-delay: 0.9s;
    }

    &.top-right {
      top: 0;
      height: 55%;
      right: 7vw;
      transform-origin: top center;
      transform: scaleY(0);
      animation: extendDown 1.5s ease forwards;
      animation-delay: 1.1s;
    }
  }

  h2 {
    font-family: 'Futura', sans-serif;
    color: $black;
    opacity: 0.8;
    font-size: 2.5vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  p {
    font-family: 'Futura', sans-serif;
    color: $black;
    opacity: 0.6;
    font-size: 1.2vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0%;
  }
}

@keyframes extendUp {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

@keyframes extendDown {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
